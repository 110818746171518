/* Pagination
================================================*/
ul.pagination {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center !important;
}

ul.pagination li {
  list-style-type: none;
  font-size: 1em;
  display: inline;
}

ul.pagination li.active a {
  color: #f4efef;
  background-color: #4285f4;
}

ul.pagination li a {
  display: block;
  float: left;
  padding: 0px 7px;
  margin-left: 2px;
  margin-right: 2px;
  color: #9FA19F;
}

ul.pagination li span {
  float: left;
  margin-right: 4px;
}

ul.pagination li a.page {
  background-color: #9FA19F;
  color: #ddd;
  font-weight: bold;
}

ul.pagination li a.page:hover {
  text-decoration: none;
  color: #fff;
}

ul.pagination li.selected a.page {
  background-color: #545C5F;
  color: #fff;
}

ul.pagination li.disabled a {
  color: #ddd;
  cursor: default;
}