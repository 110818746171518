i.btn{
  cursor: pointer;
  padding: 0px;
  box-shadow: none;
}
i.icon-sm {
  font-size: 1rem;
}

i.icon-large {
  font-size: 1.5rem;
}

td i.icon-sm {
  font-size: 1.3rem !important;
}

td i.icon-xsm {
  font-size: 1.1rem !important;
}