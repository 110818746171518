@import "validation";
@import "pagination";
@import "icon";
@import "size";
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.table tbody td{
  padding-top: 5px;
  padding-bottom:5px;
}

.table.table thead th{
  padding-top: 5px;
  padding-bottom: 10px;
}

.table-header-div-border-bottom {
  padding-bottom: 0px;
}

//body{
//  overflow-y: hidden;
//}
//
//html{
//  overflow-y: hidden;
//}

ul.pagination {
  margin-top: 0px;
  margin-bottom: 0px;
}

.table.table td, table.table th{
  padding-bottom: 5px;
  padding-top: 5px;
}

//.dt-paging {
//  text-align: left;
//  padding-left: 50px;
//  width: 100px;
//}

.custom-select {
  display: inline-block !important;
}

.dataTables_scrollBody {
  max-height: 300px;

}

#tbProductionManagement_wrapper .dataTables_scrollBody {
  overflow-x:scroll !important;
}

ul.pagination li a {
 font-size: 13px!important;
}

.pagination .page-item.active .page-link {
  background-color: #4285f4;
  color: #fff;
}

.dataTables_length {
  width: 200px;
  float: left;
  padding-left:10px;
  //margin-top: 5px!important;
}

.pagination{
  float:right;
  margin-top: 10px !important;
}

.modal-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal-body{
  padding: 15px !important;
}

label {
  margin-bottom: 5px !important;
}

.form-group {
  margin-bottom: 5px!important;
}

.modal-footer{
  padding: 15px;
}

.btn {
text-transform: none;
}

button, input {
  height: 30px;
}

.modal-dialog {
  margin: 0px auto !important;
}

#modalUpdateProduction {
  //margin-top: -26px;
}

//#tbProductionManagement_wrapper .table tbody td:first-child{
//  text-align: left!important;
//}
//
//#tbProductionManagement_wrapper  .table tbody td:nth-child(2) , .table tbody td:nth-child(6) {
//    text-align: center!important;
//  }
//
//#tbProductionManagement_wrapper  .table tbody td:nth-child(3){
//  text-align: left!important;
//}


#tbOrderReport.table tbody td:nth-child(3){
  text-align: left!important;
}



#tbOrderReport.table tbody td:nth-child(5){
  text-align: left!important;
}

#tbOrderReport.table tbody td:nth-child(7){
  text-align: left!important;
}

#tbOrderReport.table tbody td:nth-child(10){
  text-align: left!important;
}

#tbOrderReport.table tbody td:nth-child(12){
  text-align: left!important;
}
#tbOrderReport.table tbody td:nth-child(13){
  text-align: left!important;
}



#tbOrderReport.table tfoot td:nth-child(3){
  text-align: left!important;
}


#tbOrderReport.table tfoot td:nth-child(5){
  text-align: left!important;
}

#tbOrderReport.table tfoot td:nth-child(7){
  text-align: left!important;
}

#tbOrderReport.table tfoot td:nth-child(10){
  text-align: left!important;
}

#tbOrderReport.table tfoot td:nth-child(12){
  text-align: left!important;
}

#tbOrderReport.table tfoot td:nth-child(13){
  text-align: left!important;
}



#tbOrderLineReport.table tbody td:nth-child(4){
  text-align: left!important;
}

#tbOrderLineReport.table tbody td:nth-child(6){
  text-align: left!important;
}

#tbOrderLineReport.table tbody td:nth-child(9){
  text-align: left!important;
}

#tbOrderLineReport.table tbody td:nth-child(11){
  text-align: left!important;
}

#tbOrderLineReport.table tbody td:nth-child(12){
  text-align: left!important;
}

/////////

#tbZoneOrderReport.table tbody td:nth-child(3){
  text-align: left!important;
}


#tbZoneOrderReport.table tbody td:nth-child(5){
  text-align: left!important;
}

#tbZoneOrderReport.table tbody td:nth-child(7){
  text-align: left!important;
}

#tbZoneOrderReport.table tbody td:nth-child(10){
  text-align: left!important;
}

//#tbZoneOrderReport.table tbody td:nth-child(12){
//  text-align: left!important;
//}
//#tbZoneOrderReport.table tbody td:nth-child(13){
//  text-align: left!important;
//}
//
//#tbZoneOrderReport.table tbody td:nth-child(15){
//  text-align: left!important;
//}
//
//#tbZoneOrderReport.table tbody td:nth-child(14){
//  text-align: left!important;
//}


#tbZoneOrderReport.table tbody td:nth-child(11){
  text-align: left!important;
}

////
#tbZoneOrderLineReport.table tbody td:nth-child(4){
  text-align: left!important;
}

#tbZoneOrderLineReport.table tbody td:nth-child(9){
  text-align: left!important;
}
#tbZoneOrderLineReport.table tbody td:nth-child(6){
  text-align: left!important;
}

#tbZoneOrderLineReport.table tbody td:nth-child(11){
  text-align: left!important;
}

#tbZoneOrderLineReport.table tbody td:nth-child(10){
  text-align: left!important;
}
//

#tbAgentOrderLineReport.table tbody td:nth-child(4){
  text-align: left!important;
}

#tbAgentOrderLineReport.table tbody td:nth-child(9){
  text-align: left!important;
}
#tbAgentOrderLineReport.table tbody td:nth-child(6){
  text-align: left!important;
}

#tbAgentOrderLineReport.table tbody td:nth-child(11){
  text-align: left!important;
}

#tbAgentOrderLineReport.table tbody td:nth-child(10){
  text-align: left!important;
}

/////


#tbCustomerOrderReport.table tbody td:nth-child(3){
  text-align: left!important;
}

#tbCustomerOrderReport.table tbody td:nth-child(5){
  text-align: left!important;
}

#tbCustomerOrderReport.table tbody td:nth-child(7){
  text-align: left!important;
}

#tbCustomerOrderReport.table tbody td:nth-child(10){
  text-align: left!important;
}

#tbCustomerOrderReport.table tbody td:nth-child(12){
  text-align: left!important;
}
#tbCustomerOrderReport.table tbody td:nth-child(13){
  text-align: left!important;
}

#tbCustomerOrderReport.table tbody td:nth-child(11){
  text-align: left!important;
}


#tbProductionManagement.table tbody td:nth-child(2){
   text-align: left!important;

 }

#tbProductionManagement.table tbody td:nth-child(1){
  text-align: left!important;
}

.table tbody td {
  text-align: left!important;
}

.section-column{
  padding-left: 2px;
  padding-right: 0px;
}